import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { doc, setDoc } from "firebase/firestore";
import { FIRESTORE_CARTS_TABLE } from "../../../constants/FirebaseConstants";
import {
  filterProduct,
  getPaymentAndDiscountPriceForGroup,
  isPreOrderProduct,
} from "../../../helpers/product";
import uuid from "uuid/v4";
import { db } from "../../../services/firebase";
import dayjs from "dayjs";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

export function useCart() {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { data: user, status: userStatus } = useUser();
  const docRef = doc(
    firestore,
    FIRESTORE_CARTS_TABLE,
    userStatus === "loading" || !user ? "0" : user?.uid,
  );
  const { data, status } = useFirestoreDocData(docRef, { idField: "id" });
  const isLoading = status === "loading" || !user;

  const addProductToCart = async ({ product, quantity, notification }) => {
    if (user) {
      const currentCart = !!data ? data.products : [];
      const cartItem = filterProduct(currentCart, product);

      const newCart =
        cartItem === undefined
          ? [
              ...currentCart,
              {
                ...product,
                quantity: quantity ? quantity : 1,
                cartItemId: uuid(),
              },
            ]
          : currentCart.map((item) =>
              item.cartItemId === cartItem.cartItemId
                ? {
                    ...item,
                    quantity: quantity
                      ? item.quantity + quantity
                      : item.quantity + 1,
                  }
                : item,
            );

      try {
        await setDoc(
          doc(db, FIRESTORE_CARTS_TABLE, user?.uid),
          { products: newCart, updatedAt: dayjs().unix() },
          { merge: true },
        );
        if (notification) {
          message.success(t("added_to_cart"));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addProductSetToCart = async ({ products }) => {
    const currentCart = data.products;
    let newCart = [...currentCart];
    products.forEach((product) => {
      const cartItem = filterProduct(currentCart, product);
      newCart =
        cartItem === undefined
          ? [
              ...newCart,
              {
                ...product,
                quantity: product.quantity ? product.quantity : 1,
                cartItemId: uuid(),
              },
            ]
          : newCart.map((item) =>
              item.cartItemId === cartItem.cartItemId
                ? {
                    ...item,
                    quantity: product.quantity
                      ? item.quantity + product.quantity
                      : item.quantity + 1,
                  }
                : item,
            );
    });

    try {
      await setDoc(
        doc(db, FIRESTORE_CARTS_TABLE, user?.uid),
        {
          products: newCart,
          updatedAt: dayjs().unix(),
        },
        { merge: true },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addMultipleProductsToCart = async ({ products }) => {
    const currentCart = data.products;
    let cartItemsList = [...currentCart];
    products.forEach((product) => {
      const cartItem = filterProduct(currentCart, product);
      if (cartItem === undefined) {
        cartItemsList.push({
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid(),
        });
      } else {
        let existingCartItemProduct = cartItemsList.find(
          (item) => item.cartItemId === cartItem.cartItemId,
        );
        let index = cartItemsList.findIndex(
          (item) => item.cartItemId === cartItem.cartItemId,
        );
        cartItemsList[index].quantity = product.quantity
          ? existingCartItemProduct.quantity + product.quantity
          : existingCartItemProduct.quantity + 1;
      }
    });

    try {
      await setDoc(
        doc(db, FIRESTORE_CARTS_TABLE, user?.uid),
        {
          products: cartItemsList,
          updatedAt: dayjs().unix(),
        },
        { merge: true },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const decreaseProductQuantity = async ({ product }) => {
    const currentCart = data.products;
    const orderUnit = product.orderUnit || 1;
    if (product.quantity > orderUnit) {
      const newCart = currentCart.map((item) =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - orderUnit }
          : item,
      );

      try {
        await setDoc(
          doc(db, FIRESTORE_CARTS_TABLE, user?.uid),
          {
            products: newCart,
            updatedAt: dayjs().unix(),
          },
          { merge: true },
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteProductFromCart = async ({ product }) => {
    const currentCart = data.products;
    const remainingItems = (currentCart, product) =>
      currentCart.filter(
        (cartItem) => cartItem.cartItemId !== product.cartItemId,
      );

    try {
      await setDoc(doc(db, FIRESTORE_CARTS_TABLE, user?.uid), {
        products: remainingItems(currentCart, product),
        updatedAt: dayjs().unix(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAllProductsFromCart = async () => {
    try {
      if (user?.uid) {
        await setDoc(doc(db, FIRESTORE_CARTS_TABLE, user?.uid), {
          products: [],
          updatedAt: dayjs().unix(),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateCart = async ({
    groupId,
    groupSettingsUserGroup,
    selectedSalesGroup,
    products,
  }) => {
    const currentCart = data?.products;
    if (
      !products ||
      products.length === 0 ||
      !currentCart ||
      currentCart.length === 0
    )
      return null;
    else {
      const newCart = currentCart.reduce(function (updatedCart, cartItem) {
        const productFound = products.find(
          (product) => product.id === cartItem.id,
        );
        const productPriceForUser = getPaymentAndDiscountPriceForGroup(
          productFound,
          groupId,
          groupSettingsUserGroup,
          selectedSalesGroup,
        );

        // Product found and stock is available or pre-order is active for product
        if (
          !!productFound &&
          ((productFound.stock > 0 &&
            productFound.stock >= cartItem.quantity) ||
            isPreOrderProduct(productFound.pre_order))
        ) {
          const updatedCartItem = {
            ...cartItem,
            name: productFound.name,
            image: productFound.image,
            price: productPriceForUser.original,
            discount: productFound.discount,
            taxRate: productFound.taxRate,
            stock: productFound.stock,
          };
          updatedCart.push(updatedCartItem);
        }
        // Product found and stock is not enough -> quantity in cart should be equal to available stock
        else if (
          !!productFound &&
          productFound.stock > 0 &&
          productFound.stock < cartItem.quantity
        ) {
          const orderUnit = productFound.orderUnit || 1;
          const newStock =
            Math.trunc(productFound.stock / orderUnit) * orderUnit;
          const updatedCartItem = {
            ...cartItem,
            name: productFound.name,
            image: productFound.image,
            price: productPriceForUser.original,
            discount: productFound.discount,
            taxRate: productFound.taxRate,
            stock: productFound.stock,
            quantity: newStock,
          };
          updatedCart.push(updatedCartItem);
        }
        return updatedCart;
      }, []);

      if (!isEqual(newCart, currentCart)) {
        try {
          await setDoc(
            doc(db, FIRESTORE_CARTS_TABLE, user?.uid),
            {
              products: newCart,
              updatedAt: dayjs().unix(),
            },
            { merge: true },
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return {
    data,
    isLoading,
    addProductToCart,
    addProductSetToCart,
    addMultipleProductsToCart,
    decreaseProductQuantity,
    deleteProductFromCart,
    deleteAllProductsFromCart,
    updateCart,
  };
}
